import React from 'react'
import styled from 'styled-components'

import propTypes from 'prop-types'
import Tag from './Tag'
import RichText from './RichText'
import AboutHeroText from './AboutHeroText'
import PrimaryButton from './PrimaryButton'
import { HeroHeading } from './Typography'

import { BREAKPOINT, COLOR, SPACING, WIDTH } from '../constants'

const HeroSection = styled.section`
  background-color: ${(props) => {
    if (props.type === 'example') {
      return COLOR.GREEN.LIGHT
    }
    if (props.type === 'about') {
      return COLOR.TEAL.MEDIUM
    }
    if (props.type === 'pattern') {
      return COLOR.TEAL.LIGHT
    }
    if (props.type === 'principle') {
      return COLOR.BLUE.LIGHT
    }
    return COLOR.WHITE
  }};
`

const Wrapper = styled.div`
  max-width: ${(props) => {
    if (
      props.type === 'example' ||
      props.type === 'about' ||
      props.type === 'home'
    ) {
      return WIDTH.LINE_LIMIT
    }

    return '100%'
  }};
  margin: ${(props) => (props.type !== 'example' ? 0 : '0 auto')};
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: start;
  padding: ${SPACING.XXXL} ${SPACING.M};

  @media (min-width: ${BREAKPOINT.S}) {
    padding-bottom: ${SPACING.XXL};
    padding-left: ${SPACING.XXL};
    padding-right: ${SPACING.XXL};
    padding: ${(props) => {
      if (props.type === 'example' || props.type === 'about') {
        return `${SPACING.XXXL} ${SPACING.L} ${SPACING.XXL} ${SPACING.L}`
      }
      if (props.type === 'home') {
        return `${SPACING.XXXL} ${SPACING.XXL} ${SPACING.XXL} ${SPACING.XXL}`
      }
      return `${SPACING.XXXL} ${SPACING.XL} ${SPACING.XXL} ${SPACING.XL}`
    }};

    @media (min-width: ${BREAKPOINT.M}) {
      padding-top: 160px;
      padding-bottom: ${SPACING.XXXL};
      justify-content: space-between;
      max-width: ${(props) => {
        if (props.type === 'example' || props.type === 'about') {
          return WIDTH.LINE_LIMIT
        }
        return '100%'
      }};
      flex-direction: ${(props) =>
        props.type !== 'example' ? 'row' : 'column-reverse'};
    }
    @media (min-width: ${BREAKPOINT.L}) {
      max-width: ${WIDTH.WRAPPER};
      flex-direction: row;
      align-items: center;
    }
  }
`

const Content = styled.div`
  max-width: ${(props) =>
    props.type === 'about' ? WIDTH.ABOUT_HERO_LINE_LIMIT : WIDTH.LINE_LIMIT};
  margin-right: 0;

  @media (min-width: ${BREAKPOINT.M}) {
    margin-right: ${SPACING.XXXL};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  max-width: 300px;

  @media (min-width: ${BREAKPOINT.S}) {
    margin-bottom: ${SPACING.L};
  }

  @media (min-width: ${BREAKPOINT.M}) {
    max-width: 400px;
  }
`
const DescriptionContainer = styled.div`
  max-width: ${WIDTH.HOME_HERO_LIMIT};
  margin-bottom: ${SPACING.XL};
`

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: ${WIDTH.HOME_HERO_LIMIT};
  margin-bottom: ${SPACING.S};
  margin-top: -${SPACING.S};
`

const Hero = ({
  type,
  heading,
  description,
  tags,
  buttonText,
  buttonLink,
  heroImage,
}) => {
  let tagPrefix
  if (type === 'example') {
    tagPrefix = '/patterns'
  } else if (type === 'pattern') {
    tagPrefix = '/principles'
  } else tagPrefix = null

  return React.createElement(
    HeroSection,
    { type: type },
    React.createElement(
      Wrapper,
      { type: type },
      React.createElement(
        Content,
        { type: type },
        heading && type === 'about'
          ? AboutHeroText(heading)
          : React.createElement(HeroHeading, null, heading),
        description &&
          React.createElement(
            DescriptionContainer,
            null,
            RichText(description)
          ),
        tags &&
          React.createElement(
            TagWrapper,
            null,
            tags.map((tag) =>
              React.createElement(Tag, {
                key: tag.slug,
                name: tag.name,
                link: `${tagPrefix}/${tag.slug}`,
              })
            )
          ),
        buttonText &&
          buttonLink &&
          React.createElement(PrimaryButton, { to: buttonLink }, buttonText)
      ),
      React.createElement(
        ImageWrapper,
        null,
        heroImage &&
          React.createElement('img', { src: heroImage.file.url, alt: '' })
      )
    )
  )
}

Hero.propTypes = {
  type: propTypes.string,
  heading: propTypes.oneOfType([
    propTypes.string,
    propTypes.objectOf(propTypes.any),
  ]).isRequired,
  description: propTypes.objectOf(propTypes.any),
  tags: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      slug: propTypes.string,
    })
  ),
  buttonText: propTypes.string,
  buttonLink: propTypes.string,
  heroImage: propTypes.shape({
    contentType: propTypes.string,
    file: propTypes.shape({
      url: propTypes.string,
    }),
  }),
}

Hero.defaultProps = {
  type: null,
  description: null,
  tags: null,
  buttonText: null,
  buttonLink: null,
  heroImage: {
    contentType: null,
    file: {
      url: null,
    },
  },
}
export default Hero
