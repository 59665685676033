import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import { COLOR, FONT, SPACING, SHADOW } from '../constants'

const PatternCardLink = styled(Link)`
  background-color: ${COLOR.WHITE};
  text-decoration: none;
  border: 1px solid ${COLOR.GRAY.LIGHT};
  border-radius: 10px;
  box-shadow: ${SHADOW.CARD};
  height: 228px;

  &:hover {
    border: 1px solid ${COLOR.TEAL.MEDIUM};
  }
`

const PatternCardArticle = styled.article`
  max-width: 100%;
  padding: ${SPACING.L} ${SPACING.S};
`
const PatternTitle = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: 26px;
  color: ${COLOR.TEAL.MEDIUM};
  text-decoration: underline;
  margin-bottom: ${SPACING.XS};
`

const PatternSummary = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.M};
  font-weight: ${FONT.WEIGHT.REGULAR};
  letter-spacing: 0%;
  line-height: ${SPACING.L};
  color: ${COLOR.GRAY.DARK};
  text-decoration: none;
  margin-bottom: 0;
`

const PatternCard = ({ slug, name, summary }) => (
  <PatternCardLink key={slug} to={`/patterns/${slug}`} aria-label={name}>
    <PatternCardArticle>
      <PatternTitle>{name}</PatternTitle>
      <PatternSummary>{summary}</PatternSummary>
    </PatternCardArticle>
  </PatternCardLink>
)
PatternCard.propTypes = {
  slug: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  summary: propTypes.string.isRequired,
}

export default PatternCard
