import React from 'react'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { FONT, COLOR, SPACING } from '../constants'

const StyledButton = styled(Link)`
  display: inline-block;
  font-family: ${FONT.FAMILY.HEADING};
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.L};
  line-height: 25px;
  text-decoration: none;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.TEAL.MEDIUM};
  border-radius: 200px;
  border: 1px solid ${COLOR.TEAL.MEDIUM};
  padding: 8px ${SPACING.S};
  margin-right: ${SPACING.XS};
  transition: 0.2s ease-in background-color, 0.2s ease-in color;
  filter: drop-shadow(0px 4px 4px rgba(45, 55, 72, 0.12));

  &:visited {
    color: ${COLOR.WHITE};
  }
  &:hover {
    color: ${COLOR.TEAL.MEDIUM};
    background-color: ${COLOR.WHITE};
    border: 1px solid ${COLOR.TEAL.MEDIUM};
  }
`

const PrimaryButton = ({ to, children }) => (
  <StyledButton to={to}>{children}</StyledButton>
)

PrimaryButton.propTypes = {
  children: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
}

export default PrimaryButton
