import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import { COLOR, FONT, SPACING, SHADOW } from '../constants'

const ExampleCardLink = styled(Link)`
  background-color: ${COLOR.WHITE};
  text-decoration: none;
  border: 1px solid ${COLOR.GRAY.LIGHT};
  border-radius: 10px;
  box-shadow: ${SHADOW.CARD};

  &:hover {
    border: 1px solid ${COLOR.TEAL.MEDIUM};
  }
`

const StyledImage = styled(Img)`
  border-radius: 10px 10px 0px 0px;
  height: 240px;
  object-fit: contain;
`
const ImageContainer = styled.div`
  max-height: 100%;
`

const ExampleCardArticle = styled.article`
  max-width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING.M};
`

const Organisation = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.S};
  font-weight: ${FONT.WEIGHT.MEDIUM};
  line-height: 1.5;
  color: ${COLOR.GRAY.MEDIUM};
  margin-bottom: ${SPACING.XXS};
`
const ExampleTitle = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: 20px;
  color: ${COLOR.GREEN.MEDIUM};
  text-decoration: underline;
`

const ExampleCard = ({ slug, name, thumbnail, organisation }) => (
  <ExampleCardLink key={slug} to={`/examples/${slug}`} aria-label={name}>
    <ExampleCardArticle>
      <ImageContainer>
        <StyledImage
          fluid={thumbnail.fluid}
          alt={thumbnail.description}
          key={thumbnail.fluid.src}
        />
      </ImageContainer>
      <TextContainer>
        <Organisation>{organisation}</Organisation>
        <ExampleTitle>{name}</ExampleTitle>
      </TextContainer>
    </ExampleCardArticle>
  </ExampleCardLink>
)
ExampleCard.propTypes = {
  slug: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  organisation: propTypes.string.isRequired,
  thumbnail: propTypes.shape({
    fluid: propTypes.shape({
      aspectRatio: propTypes.number.isRequired,
      base64: propTypes.string.isRequired,
      sizes: propTypes.string.isRequired,
      src: propTypes.string.isRequired,
      srcSet: propTypes.string.isRequired,
    }),
    description: propTypes.string.isRequired,
  }).isRequired,
}

export default ExampleCard
