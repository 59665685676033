import styled from 'styled-components'
import { BREAKPOINT, SPACING } from '../constants'

const PatternGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 18px;
  grid-row-gap: 32px;
  margin-bottom: ${SPACING.L};
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${BREAKPOINT.L}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default PatternGrid
