import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import { BREAKPOINT, FONT, COLOR, SPACING, WIDTH } from '../constants'
import SecondaryButton from './SecondaryButton'

const Container = styled.div`
  max-width: ${WIDTH.WRAPPER};
  display: flex;
  flex-direction: column;
  margin: 80px auto 120px auto;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 0 auto;
  padding-top: ${SPACING.XXL};
  padding-bottom: ${SPACING.XXL};
  padding-left: ${SPACING.XL};
  padding-right: ${SPACING.XL};
  justify-content: space-between;

  @media (min-width: ${BREAKPOINT.S}) {
    padding-left: ${SPACING.XXL};
    padding-right: ${SPACING.XXL};
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }

  @media (min-width: ${BREAKPOINT.L}) {
    padding-left: 0;
    padding-right: 0;
  }
`

const PrincipleContainer = styled.div`
  background-color: ${COLOR.BLUE.LIGHT};
`

const PatternContainer = styled.div`
  background-color: ${COLOR.TEAL.LIGHT};
`

const ExampleContainer = styled.div`
  background-color: ${COLOR.GREEN.LIGHT};
`

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: ${SPACING.L};
  margin-left: 0;
  @media (min-width: 800px) {
    margin-left: ${SPACING.XL};
    margin-top: 0px;
  }
`

const Img = styled.img`
  max-width: 70px;
  max-height: 40px;
  margin-bottom: ${SPACING.S};
`

const H2 = styled.h2`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${FONT.SIZE.XXL};
  color: ${COLOR.GR};
  margin-top: 0;
  margin-bottom: 0;
`

export const P = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.REGULAR};
  letter-spacing: 0%;
  line-height: ${SPACING.L};
  color: ${COLOR.GRAY.DARK};
  margin-bottom: 0;
  max-width: 100%;
  padding-top: ${SPACING.M};
  margin-right: ${SPACING.XL};
  flex: 1;

  @media (min-width: 800px) {
    max-width: 280px;
  }
`

const LibraryStructure = () => (
  <StaticQuery
    query={graphql`
      {
        allContentfulContentTypeInfo {
          edges {
            node {
              contentType
              bwIcon {
                file {
                  url
                }
                description
              }
              homePageDefinition
            }
          }
        }
      }
    `}
    render={(data) => {
      const principle = data.allContentfulContentTypeInfo.edges.find(
        (item) => item.node.contentType === 'principle'
      ).node

      const pattern = data.allContentfulContentTypeInfo.edges.find(
        (item) => item.node.contentType === 'pattern'
      ).node

      const example = data.allContentfulContentTypeInfo.edges.find(
        (item) => item.node.contentType === 'example'
      ).node
      return (
        <Container>
          <PrincipleContainer>
            <ContentContainer>
              <HeadingContainer>
                <Img
                  src={principle.bwIcon.file.url}
                  alt={principle.bwIcon.description}
                />
                <H2>Principles</H2>
              </HeadingContainer>
              <P>{principle.homePageDefinition}</P>
              <ButtonContainer>
                <SecondaryButton to="/principles">
                  Browse principles
                </SecondaryButton>
              </ButtonContainer>
            </ContentContainer>
          </PrincipleContainer>

          <PatternContainer>
            <ContentContainer>
              <HeadingContainer>
                <Img
                  src={pattern.bwIcon.file.url}
                  alt={pattern.bwIcon.description}
                  style={{ transform: 'translateX(-8px)' }}
                />
                <H2>Patterns</H2>
              </HeadingContainer>
              <P>{pattern.homePageDefinition}</P>
              <ButtonContainer>
                <SecondaryButton to="/patterns">
                  Browse patterns
                </SecondaryButton>
              </ButtonContainer>
            </ContentContainer>
          </PatternContainer>

          <ExampleContainer>
            <ContentContainer>
              <HeadingContainer>
                <Img
                  src={example.bwIcon.file.url}
                  alt={example.bwIcon.description}
                />
                <H2>Examples</H2>
              </HeadingContainer>
              <P>{example.homePageDefinition}</P>
              <ButtonContainer>
                <SecondaryButton to="/examples">
                  Browse examples
                </SecondaryButton>
              </ButtonContainer>
            </ContentContainer>
          </ExampleContainer>
        </Container>
      )
    }}
  />
)

export default LibraryStructure
