import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { BREAKPOINT, FONT, COLOR, SPACING } from '../constants'

const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${SPACING.M};
  margin-top: ${SPACING.XXXL};
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`
const ImageContainer = styled.div`
  margin-right: ${SPACING.L};

  @media (min-width: ${BREAKPOINT.S}) {
    margin-right: ${SPACING.S};
  }
`
const Img = styled.img`
  max-height: 60px;
  margin-bottom: 0;
`
export const Heading = styled.h2`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  color: ${COLOR.GRAY.DARK};
  line-height: ${FONT.SIZE.XXL};
  margin-bottom: 0;
  margin-top: 0;
`

const LineContainer = styled.div`
  display: none;
  flex-grow: 1;
  align-items: center;
  position: relative;
  padding-left: 24px;

  @media (min-width: ${BREAKPOINT.S}) {
    display: inline-block;
  }
`
const Line = styled.div`
  border-bottom: 1px solid ${COLOR.GRAY.LIGHT};
  top: 50%;
  transform: translateY(3px);
  position: relative;
`

const SectionHeading = ({ image, children }) => (
  <Wrapper>
    <ContentContainer>
      {image && (
        <ImageContainer>
          <Img src={image.file.url} alt={image.description} />
        </ImageContainer>
      )}
      <Heading>{children}</Heading>
    </ContentContainer>
    <LineContainer>
      <Line />
    </LineContainer>
  </Wrapper>
)

SectionHeading.propTypes = {
  children: propTypes.string.isRequired,
  image: propTypes.shape({
    description: propTypes.string,
    file: propTypes.shape({
      url: propTypes.string,
    }),
  }),
}

SectionHeading.defaultProps = {
  image: null,
}

export default SectionHeading
