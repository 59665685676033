import React from 'react'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { FONT, COLOR, SPACING } from '../constants'

const StyledTag = styled(Link)`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.XS};
  line-height: 1.5;
  font-weight: ${FONT.WEIGHT.BOLD};
  text-decoration: none;
  color: ${COLOR.WHITE};
  background-color: ${COLOR.GRAY.DARK};
  border-radius: 500px;
  border: 1px solid ${COLOR.GRAY.DARK};
  padding: 6px ${SPACING.S};
  margin-right: ${SPACING.XS};
  margin-top: ${SPACING.S};
  transition: 0.2s ease-in background-color, 0.2s ease-in color;
  &:visited {
    color: ${COLOR.WHITE};
  }
  &:hover {
    color: ${COLOR.GRAY.DARK};
    background-color: ${COLOR.WHITE};
    border: 1px solid ${COLOR.GRAY.DARK};
  }
`

const Tag = ({ name, link }) => <StyledTag to={link}>{name}</StyledTag>

Tag.propTypes = {
  name: propTypes.string.isRequired,
  link: propTypes.string.isRequired,
}

export default Tag
