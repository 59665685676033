import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { BREAKPOINT, FONT, COLOR, SPACING, WIDTH } from '../constants'
import { P } from './Typography'
import PrimaryButton from './PrimaryButton'

const Container = styled.section`
  background-color: ${COLOR.GRAY.PALE};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  max-width: ${WIDTH.WRAPPER};
  margin: 0 auto;
  padding-left: ${SPACING.M};
  padding-right: ${SPACING.M};
  padding-top: ${SPACING.XXXL};
  padding-bottom: ${SPACING.XXXL};
  margin-top: ${SPACING.XXXL};

  @media (min-width: ${BREAKPOINT.S}) {
    align-items: center;
  }

  @media (min-width: 750px) {
    flex-direction: ${(props) =>
      props.position === 'true' ? 'row-reverse' : 'row'};
    margin-top: ${SPACING.XXXL};
    padding-top: 140px;
    padding-bottom: 140px;
  }
`

const MainContent = styled.div`
  max-width: ${WIDTH.HOME_HERO_LIMIT};
`
const ButtonContainer = styled.div`
  margin-top: ${SPACING.XL};
`

const H2 = styled.h2`
  font-family: ${FONT.FAMILY.HEADING};
  font-size: ${FONT.SIZE.XXL};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: ${FONT.SIZE.XXL};
  color: ${COLOR.GR};
`

const Img = styled.img`
  margin-bottom: ${SPACING.XL};
  padding-right: ${SPACING.XXL};
  padding-left: ${SPACING.XXL};

  @media (min-width: 750px) {
    margin-bottom: 0;
  }
`

const ShareBanner = ({ heading, image, position, bodyText }) => (
  <Container>
    <Wrapper position={position}>
      <MainContent>
        <H2>{heading}</H2>
        <P>{bodyText}</P>
        <ButtonContainer>
          <PrimaryButton to="https://docs.google.com/forms/d/e/1FAIpQLSdqhILqoAWF9wPgXCLLO53X8Ca987ngCz8aT2PmHneOqWY8RA/viewform?usp=sf_link">
            Get in touch
          </PrimaryButton>
        </ButtonContainer>
      </MainContent>
      <Img src={image.file.url} alt={image.description} />
    </Wrapper>
  </Container>
)

ShareBanner.propTypes = {
  heading: propTypes.string.isRequired,
  bodyText: propTypes.string.isRequired,
  position: propTypes.bool.isRequired,
  image: propTypes.shape({
    file: propTypes.shape({
      url: propTypes.string,
    }),
    description: propTypes.string,
  }),
}

ShareBanner.defaultProps = {
  image: {
    description: 'illustration',
  },
}

export default ShareBanner
