import React from 'react'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { P, H2, H3, H4, AboutHeroHeading, AboutHeroLink } from './Typography'

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <P as="strong">{text}</P>,
    [MARKS.ITALIC]: (text) => <P as="em">{text}</P>,
    [MARKS.UNDERLINE]: (text) => <P as="u">{text}</P>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <AboutHeroHeading>{children}</AboutHeroHeading>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <AboutHeroHeading>{children}</AboutHeroHeading>
    ),
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
    // [BLOCKS.EMBEDDED_ASSET]: (node) => {
    //   const fluid = useContentfulImage(node.data.target.fields.file.url)
    //   return (
    //     <Image
    //       fluid={fluid}
    //       alt={node.data.target.fields.title}
    //       caption={node.data.target.fields.description}
    //     />
    //   )
    // },
    // [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    //   return <LinkResolver input={node.data.target}>{children}</LinkResolver>
    // },
    [INLINES.HYPERLINK]: (node, children) => (
      <AboutHeroLink href={node.data.uri} rel="noopener noreferrer">
        {children}
      </AboutHeroLink>
    ),
    // [BLOCKS.EMBEDDED_ENTRY]: (node) => {
    //   const { fields } = node.data.target
    //   const contentType = node.data.target.sys.contentType.sys.id
    //   switch (contentType) {
    //     case 'colorBlock':
    //       return <ColorBlock input={fields} />
    //     default:
    //       return null
    //   }
    // },
  },
}
const AboutHeroText = (input) => (
  <>{documentToReactComponents(input.json, options)}</>
)

// AboutHeroText.propTypes = {
//   input: propTypes.objectOf(propTypes.any).isRequired,
// }
export default AboutHeroText
