import React from 'react'
import { Link } from 'gatsby'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { FONT, COLOR, SPACING } from '../constants'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  &:visited {
    color: ${COLOR.WHITE};
  }
  &:hover {
    p {
      text-decoration: underline;
      text-decoration-color: ${COLOR.GREEN.MEDIUM};
    }
  }
`
const Text = styled.p`
  color: ${COLOR.GREEN.MEDIUM};
  font-family: ${FONT.FAMILY.HEADING};
  font-weight: ${FONT.WEIGHT.BOLD};
  font-size: ${FONT.SIZE.L};
  line-height: 26px;
  margin-bottom: 0px;
  text-decoration: underline;
  text-decoration-color: ${COLOR.GREEN.MEDIUM};
`

const ArrowIcon = styled.svg`
  margin-left: ${SPACING.XS};
`

const ArrowLink = ({ to, children }) => (
  <Container>
    <StyledLink to={to}>
      <Text>{children}</Text>
      <ArrowIcon
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <path
          d="M8.2 17.1L8.2 15.1 20.2 15.1 14.7 9.6 16.1 8.2 24 16.1 16.1 24 14.7 22.6 20.2 17.1 8.2 17.1Z"
          fill="#549BAD"
        />
        <circle
          cx="16"
          cy="16"
          r="15"
          style={{ strokeWidth: 2, stroke: '#549BAD' }}
        />
      </ArrowIcon>
    </StyledLink>
  </Container>
)

ArrowLink.propTypes = {
  children: propTypes.string.isRequired,
  to: propTypes.string.isRequired,
}

export default ArrowLink
