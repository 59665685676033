import React from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import PatternCard from '../components/PatternCard'
import PatternGrid from '../components/PatternGrid'
import ExampleGrid from '../components/ExampleGrid'
import ExampleCard from '../components/ExampleCard'
import ContentWrapper from '../components/ContentWrapper'
import SectionHeading from '../components/SectionHeading'
import ArrowLink from '../components/ArrowLink'
import LibraryStructure from '../components/LibraryStructure'
import ShareBanner from '../components/ShareBanner'

const IndexPage = ({
  data: { homePage, latestPatterns, latestExamples, shareBanner },
}) => (
  <Layout title="Design Patterns for Mental Health">
    <Hero
      type="home"
      heading={homePage.pageHeading}
      heroImage={homePage.heroImage}
      description={homePage.heroDescription}
      buttonText="Browse principles"
      buttonLink="/principles"
    />

    <section>
      <LibraryStructure />
    </section>
    <ContentWrapper>
      {latestPatterns && (
        <section>
          <SectionHeading>Latest patterns</SectionHeading>
          <PatternGrid>
            {latestPatterns.nodes.map((pattern) => (
              <PatternCard
                key={pattern.slug}
                slug={pattern.slug}
                name={pattern.name}
                summary={pattern.summary}
              />
            ))}
          </PatternGrid>
          <ArrowLink to="/patterns">All patterns</ArrowLink>
        </section>
      )}
      {latestExamples && (
        <section>
          <SectionHeading>Latest examples</SectionHeading>
          <ExampleGrid>
            {latestExamples.nodes.map((example) => (
              <ExampleCard
                key={example.slug}
                slug={example.slug}
                name={example.name}
                thumbnail={example.thumbnail}
                organisation={example.contributingOrganisation.name}
              />
            ))}
          </ExampleGrid>
          <ArrowLink to="/examples">All examples</ArrowLink>
        </section>
      )}
    </ContentWrapper>
    {shareBanner && (
      <ShareBanner
        heading={shareBanner.heading}
        image={shareBanner.icon}
        position={shareBanner.iconPosition}
        bodyText={shareBanner.bodyText}
      />
    )}
  </Layout>
)
IndexPage.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  {
    homePage: contentfulHomePage(setAsHomepage: { eq: "yes" }) {
      id
      pageHeading
      heroDescription {
        json
      }
      heroImage {
        file {
          url
        }
      }
    }
    latestPatterns: allContentfulPattern(
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        name
        summary
        slug
      }
    }
    latestExamples: allContentfulExample(
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        contributingOrganisation {
          name
        }
        thumbnail {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
          description
        }
        name
        slug
      }
    }
    shareBanner: contentfulShareBanner(type: { eq: "example" }) {
      heading
      bodyText
      iconPosition
      icon {
        file {
          url
        }
      }
    }
  }
`

export default IndexPage
